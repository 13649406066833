import {
    AppBar,
    Box,
    Button,
    CssBaseline,
    IconButton,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material"
import { FunctionComponent, PropsWithChildren, useContext, useState } from "react"
import { Drawer } from "./Drawer"
import SkillIcon from "../../assets/skill-icon.png"
import { Gear, List } from "@phosphor-icons/react"
import ProductContext from "../../contexts/ProductContext"
import { PageLoader } from "../PageLoader/PageLoader"
import { VOICE_APPS } from "../../configs/voiceApps"
import { PageError } from "../PageError/PageError"
import { DataUpdateContainer } from "../DataUpdate/DataUpdateContainer"
import { useLocation } from "react-router-dom"
import { useCookies } from "react-cookie"
import { url } from "../../configs/loginConfig"

export const drawerWidth = 300

export const Layout: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const location = useLocation()
    const { loadingProduct, product, error } = useContext(ProductContext)

    const [cookies, setCookie] = useCookies(["user", "token"])
    const theme = useTheme()
    const [mobileOpen, setMobileOpen] = useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const handleSignout = () => {
        setCookie("user", null)
        setCookie("token", null)
        window.location.href = url
    }

    const voiceApp = VOICE_APPS.filter((i) => i.id === product)?.[0]

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <List />
                    </IconButton>
                    {location.pathname?.includes("administration") ? (
                        <Gear size={40} color="white" />
                    ) : (
                        <img
                            src={voiceApp?.icon || SkillIcon}
                            width={40}
                            alt="Skill icon logo"
                        />
                    )}

                    <Typography
                        variant="h6"
                        noWrap
                        sx={{
                            marginLeft: theme.spacing(2),
                            flexGrow: 1,
                        }}
                    >
                        {voiceApp?.title ||
                        location.pathname?.includes("administration")
                            ? "Administration"
                            : "Alle Skills"}
                    </Typography>
                    {!location.pathname?.includes("administration") && (
                        <DataUpdateContainer voiceApp={voiceApp} />
                    )}

                    {cookies.user && cookies.token && (
                        <>
                            <Typography variant="caption" sx={{ mr: 2 }}>
                                Hallo: {cookies.user}
                            </Typography>
                            <Button
                                sx={{
                                    background: "white",
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    color: "#00345e",
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "white",
                                        color: "orange",
                                    },
                                }}
                                onClick={handleSignout}
                            >
                                Abmelden
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>

            <Drawer
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                drawerWidth={drawerWidth}
            />
            <Box
                sx={{
                    flexGrow: 1,
                    p: 3,
                    backgroundColor: "#E0E1ED",
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    position: "relative",
                }}
            >
                <Toolbar />

                <PageLoader
                    loading={loadingProduct}
                    appName={
                        VOICE_APPS?.filter((i) => i.id === product)?.[0]?.title || ""
                    }
                />
                <PageError
                    appName={
                        VOICE_APPS?.filter((i) => i.id === product)?.[0]?.title || ""
                    }
                    error={error}
                />
                {children}
            </Box>
        </Box>
    )
}
