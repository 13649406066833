import axios from "axios"

const client = axios.create({
    baseURL: "https://iosyvib33k.execute-api.eu-central-1.amazonaws.com/dev/",
})

export const pushClient = axios.create({
    baseURL: "https://rcpqz2qygi.execute-api.eu-central-1.amazonaws.com/prod/",
})

export const converterClient = axios.create({
    baseURL: "https://wy8v6ok9b6.execute-api.eu-central-1.amazonaws.com/dev",
})

export const ssmlClient = axios.create({
    baseURL: "https://f5gjl2y4rj.execute-api.eu-central-1.amazonaws.com/dev/",
})

export const spotlightClient = axios.create({
    baseURL: "https://6ahlvs3rwe.execute-api.eu-central-1.amazonaws.com/dev/spotlights",
})

/**
 * The following Interceptors will intercept all request to the API and inject the Header Authorization with the token generated by AWS Cognito
 * This should be done only after you add the Authorizer in all the APIs in AWS API Gateway with the id (iosyvib33k and rcpqz2qygi), otherwise, you will
 * get Errors like 401 or 403.
 */

/**client.interceptors.request.use(
    (config) => {
        if (config.method === "get" || config.method === "post")
            config.headers["Authorization"] =
                "Bearer " + JSON.parse(window.sessionStorage.getItem("token") ?? "")
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

pushClient.interceptors.request.use(
    (config) => {
        if (config.method === "get" || config.method === "post")
            config.headers["Authorization"] =
                "Bearer " + JSON.parse(window.sessionStorage.getItem("token") ?? "")
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)*/

converterClient.interceptors.request.use(
    (config) => {
        if (config.method === "get" || config.method === "post") {
            config.headers["Authorization"] =
                "Bearer " + JSON.parse(window.sessionStorage.getItem("token") ?? "")
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default client
