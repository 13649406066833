import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    GridSize,
    IconButton,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material"
import { PauseCircleFilled, PlayCircleFilled } from "@mui/icons-material"
import Xarrow from "react-xarrows"
import { MausCDSItem } from "../../types"
import { CheckCircle, XCircle } from "@phosphor-icons/react"
import { QuestionConfig } from "../../configs/questionConfig"
import { useCookies } from "react-cookie"
type MausQuestionProps = {
    item?: MausCDSItem
}

const MausQuestion: React.FunctionComponent<MausQuestionProps> = ({ item }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const [isPlaying, setIsPlaying] = useState<number>(-1)
    const [moreText, setMoreText] = useState<string>("")
    const [openMoreText, setOpenMoreText] = useState<boolean>(false)

    const audioRef = useRef<HTMLAudioElement>()

    const [cookies] = useCookies(["user", "token"])
    useEffect(() => {
        const user = cookies.user
        const token = cookies.token
        if (!token && !user) {
            navigate("/")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate])

    useEffect(() => {
        return () => {
            setIsPlaying(-1)
            audioRef?.current?.pause()
        }
    }, [item])

    const playAudio = (index: number) => {
        if (isPlaying !== -1) {
            setIsPlaying(-1)
            audioRef?.current?.pause()
        } else {
            setIsPlaying(index)
            audioRef.current = new Audio(item?.media[index]?.audio)
            audioRef.current.play()
        }
    }

    const handleMoreClick = (text: string) => {
        setOpenMoreText(true)
        setMoreText(text)
    }

    const handleMoreDialogClose = () => {
        setOpenMoreText(false)
    }

    //console.log("items", item)

    return (
        <Grid container spacing={4} justifyContent="space-between">
            {item?.media.map((media, index) => (
                <Grid item xs={QuestionConfig?.[index]?.size} key={index}>
                    <Card id={QuestionConfig?.[index]?.id}>
                        <CardContent
                            style={{ overflow: "hidden", padding: 8, height: 85 }}
                        >
                            <Grid container spacing={0}>
                                <Grid item xs={QuestionConfig?.[index]?.contentSize}>
                                    <Typography
                                        variant={
                                            QuestionConfig?.[index]?.primary
                                                ? "h6"
                                                : "body2"
                                        }
                                    >
                                        {QuestionConfig?.[index]?.prefix}{" "}
                                        {media?.text?.slice(
                                            0,
                                            QuestionConfig?.[index]?.textSlice || 150
                                        )}
                                        {media?.text?.length >
                                            (QuestionConfig?.[index]?.textSlice ||
                                                280) && (
                                            <>
                                                ...
                                                <button
                                                    style={{
                                                        background: "none",
                                                        border: "none",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        handleMoreClick(media?.text)
                                                    }
                                                >
                                                    [Mehr anzeigen]
                                                </button>
                                            </>
                                        )}
                                    </Typography>
                                    {QuestionConfig?.[index]?.suffix}
                                </Grid>
                                <Grid
                                    item
                                    xs={
                                        (12 -
                                            Number(
                                                QuestionConfig?.[index]?.contentSize
                                            )) as GridSize
                                    }
                                >
                                    <Grid
                                        container
                                        spacing={0}
                                        style={{ textAlign: "center", marginTop: 5 }}
                                    >
                                        <Grid item xs={6}>
                                            {Object.keys(
                                                item?.media?.[index]?.validation ||
                                                    {}
                                            ).filter(
                                                (key) =>
                                                    item?.media?.[index]
                                                        ?.validation?.[key]
                                                        .successfully === false
                                            ).length > 0 ? (
                                                <Tooltip title="Checks sind fehlgeschlagen">
                                                    <XCircle
                                                        size={25}
                                                        color={
                                                            theme.palette.error.main
                                                        }
                                                        style={{
                                                            marginLeft: 15,
                                                        }}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Alle Checks bestanden">
                                                    <IconButton>
                                                        <CheckCircle
                                                            size={25}
                                                            color={
                                                                theme.palette.success
                                                                    .main
                                                            }
                                                            style={{
                                                                marginLeft: 15,
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <IconButton
                                                onClick={() => playAudio(index)}
                                            >
                                                {isPlaying === index ? (
                                                    <PauseCircleFilled fontSize="small" />
                                                ) : (
                                                    <PlayCircleFilled fontSize="small" />
                                                )}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    {QuestionConfig[index].arrow.map((arr, arrIndex) => {
                        if (
                            item?.media?.length > index + 1 &&
                            QuestionConfig.length > index + 1
                        ) {
                            // Ausnahme, bedingt durch Struktur in QuestionConfig
                            if (item.media.length === 7 && index === 5) return null
                            return (
                                <Xarrow
                                    key={arrIndex}
                                    start={QuestionConfig[index].id}
                                    end={
                                        arr.endArrow ||
                                        QuestionConfig[index + 1 + arrIndex].id
                                    }
                                    headSize={4}
                                    startAnchor={arr.startAnchor || "bottom"}
                                    endAnchor={arr.endAnchor || "top"}
                                    color="#00345e"
                                    strokeWidth={3}
                                    labels={
                                        <div style={arr.style || { marginLeft: 30 }}>
                                            <Typography variant="caption">
                                                {arr.arrowLabel}
                                            </Typography>
                                        </div>
                                    }
                                />
                            )
                        }
                        return null
                    })}
                </Grid>
            ))}
            <Dialog open={openMoreText} onClose={handleMoreDialogClose}>
                <DialogContent>
                    <Typography>{moreText}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleMoreDialogClose}>
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default MausQuestion
