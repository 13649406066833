import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { useContext, useEffect, useState } from "react"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import deLocale from "date-fns/locale/de"
import ProductContext from "../../contexts/ProductContext"
import { fetchSpotlight, fetchSpotlightsBySkill } from "../../services/rest.service"
import { useParams } from "react-router-dom"
import { SpotlightForm } from "./SpotlightForm"
import { v4 as uuidv4 } from "uuid"

const getStageChip = (stage) =>
    stage === "live" ? (
        <Chip label="LIVE" color="success" />
    ) : stage === "test" ? (
        <Chip label="TEST" color="info" />
    ) : (
        <Chip label="DRAFT" color="default" />
    )

const getDate = (spotlight) => {
    const format = (dateStr) => new Date(dateStr).toISOString().split("T")[0]

    return (
        (spotlight?.offerFrom ? "von " + format(spotlight?.offerFrom) + " " : "") +
        (spotlight?.offerUntil ? "bis " + format(spotlight?.offerUntil) : "")
    )
}

export const Spotlights = () => {
    const { product } = useContext(ProductContext)

    const [spotlights, setSpotlights] = useState([])
    const [formValues, setFormValues] = useState(null)

    const { id } = useParams()

    const init = async () => {
        const result = await fetchSpotlightsBySkill(product.toLowerCase())
        console.log("result", result)
        setSpotlights(result ?? [])
    }

    const loadFormValues = async (id) => {
        const result = await fetchSpotlight(id)
        console.log("result", result)
        setFormValues(result)
    }

    console.log("params", id)

    useEffect(() => {
        product && init()
    }, [product])

    useEffect(() => {
        if (id === "new") {
            setFormValues({ id: uuidv4() })
            return
        }

        if (id?.length && id !== ":id") {
            loadFormValues(id)
        } else {
            setFormValues(null)
        }
    }, [id])
    if (!product) {
return null
    }

    return formValues !== null ? (
        <SpotlightForm inititalValues={formValues} />
    ) : (
        <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                <Card>
                    <CardContent>
                    <Typography variant="h5">
                                Spotlights
                            </Typography>
                        <Button variant="contained" style={{ float: "right" }}
                        onClick={() => {
                            window.location.href =

                            "/"+product.toLowerCase()+"/spotlights/new" 
                        }}
                        >
                            Erstellen
                        </Button>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight: "bold"}} key="date">Name</TableCell>
                                    <TableCell style={{fontWeight: "bold"}} key="singleparts">Zeitraum</TableCell>
                                    <TableCell style={{fontWeight: "bold"}} key="singlepartErrors">
                                        Stage
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!spotlights?.length &&
                                    spotlights.map((spotlight) => (
                                        <TableRow
                                            key={spotlight.id}
                                            onClick={() => {
                                                window.location.href =
                                                    "/"+product.toLowerCase()+"/spotlights/" +
                                                    spotlight.id
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <TableCell>{spotlight.title}</TableCell>
                                            <TableCell>
                                                {" "}
                                                {getDate(spotlight)}{" "}
                                            </TableCell>
                                            <TableCell>
                                                {" "}
                                                {getStageChip(spotlight.stage)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </LocalizationProvider>
        </Grid>
    )
}
