import { Card, CardContent, Grid, Typography, TextField, Checkbox, Button, IconButton, Tooltip } from '@mui/material';
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { MuiChipsInput } from 'mui-chips-input';
import { InputActions } from './InputActions';


const DynamicCard = ({ config, variant, formValues, setFormValues }) => {
    const handleInputChange = (e, name) => {
        setFormValues({ ...formValues, [name]: e.target.type === "checkbox" ? e.target.checked ?? false : e.target.value });
    };

    const handleInputArrayChange = (array, name) => {
        setFormValues({ ...formValues, [name]: array});
    };

    const handleDateChange = (date, name) => {
        setFormValues({ ...formValues, [name]: date });
    };

    return (
        <Card style={{ maxWidth: "none", marginTop: 20 }}>
            <CardContent>
                <Grid container spacing={2}>
                    {config.map((field, index) => {
                        const isHidden = field.variant?.[variant]?.hidden;

                        if (isHidden) {
                            return null;
                        }

                        return (
                            <Grid item xs={12} key={index}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3} style={{ alignItems: "center", display: "flex" }}>
                                        <Typography variant="body1">{field.label}</Typography>
                                        {field.type === "boolean" || (field.optional && field.type === "date") ? (
                                            <Checkbox
                                                checked={formValues[field.name] || false}
                                                onChange={(e) => handleInputChange(e, field.name)}
                                            />
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={5}>
                                        {field.type === "text" ? (
                                            <TextField
                                                fullWidth
                                                id={field.name}
                                                variant="outlined"
                                                value={formValues[field.name] ?? ""}
                                                onChange={(e) => handleInputChange(e, field.name)}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{endAdornment: <InputActions field={field} value={formValues[field.name]} />}}

                                            />
                                        ):
                                        field.type === "text-array" ? (
                                            <MuiChipsInput
                                                fullWidth
                                                id={field.name}
                                                variant="outlined"
                                                value={formValues[field.name] ?? []}
                                                onChange={(array) => handleInputArrayChange(array, field.name)}
                                                InputLabelProps={{ shrink: true }}
                                                placeholder=''
                                            />
                                        ) 
                                        : field.type === "date" ? (
                                            <DateTimePicker
                                                value={formValues[field.name] ?? null}
                                                onChange={(date) => handleDateChange(date, field.name)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={4} style={{ fontSize: 12, color: "#666" }}>
                                        <Typography variant="body2">{field.description}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default DynamicCard;
