// @ts-nocheck
import {
    Color,
    createTheme,
    PaletteColorOptions,
    PaletteMode,
    PaletteOptions,
} from "@mui/material"
import {
    ColorPartial,
    CommonColors,
    PaletteAugmentColorOptions,
    PaletteColor,
    PaletteTonalOffset,
    TypeAction,
    TypeBackground,
    TypeDivider,
    TypeText,
} from "@mui/material/styles/createPalette"

declare module "@mui/material/styles" {
    interface Palette {
        common: CommonColors
        mode: PaletteMode
        contrastThreshold: number
        tonalOffset: PaletteTonalOffset
        primary: PaletteColor
        secondary: PaletteColor
        tertiary: PaletteColor
        error: PaletteColor
        warning: PaletteColor
        info: PaletteColor
        success: PaletteColor
        grey: Color
        text: TypeText
        divider: TypeDivider
        action: TypeAction
        background: TypeBackground
        getContrastText: (background: string) => string
        augmentColor: (options: PaletteAugmentColorOptions) => PaletteColor
    }
    interface PaletteOptions {
        primary?: PaletteColorOptions
        secondary?: PaletteColorOptions
        tertiary?: PaletteColorOptions
        error?: PaletteColorOptions
        warning?: PaletteColorOptions
        info?: PaletteColorOptions
        success?: PaletteColorOptions
        mode?: PaletteMode
        tonalOffset?: PaletteTonalOffset
        contrastThreshold?: number
        common?: Partial<CommonColors>
        grey?: ColorPartial
        text?: Partial<TypeText>
        divider?: string
        action?: Partial<TypeAction>
        background?: Partial<TypeBackground>
        getContrastText?: (background: string) => string
    }
}

const palette: PaletteOptions = {
    primary: {
        main: "#00345E",
        light: "#BEE3E3",
        dark: "#001B30",
    },
    secondary: {
        main: "#DEBB71",
        light: "#EDD5A2",
        dark: "#B28629",
    },
    tertiary: {
        main: "#DEBB71",
        light: "#EDD5A2",
        dark: "#B28629",
    },
    grey: {
        "900": "#15191E", // grey10
        "800": "#313C48", // grey25
        "700": "#546272", // grey40
        "600": "#91A1B2", // grey60
        "500": "#D2DCE7", // grey85
        "400": "#E4E6EC", // grey90
        "300": "#F4F4F4", // grey95
        "200": "#FAFAFA", // grey98
    },
    common: {
        white: "#FFFFFF",
    },
    success: {
        main: "#519175",
    },
    info: {
        main: "#0288D1",
    },
    warning: {
        main: "#FECC71",
    },
    error: {
        main: "#BF5840",
    },
}

export const theme = createTheme({
    palette,

    components: {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: "79px !important",
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: palette.primary.main,
                    color: palette.common?.white,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: palette.primary.dark,
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 20,
                    //backgroundColor: "rgba(255, 255, 255, 0.4)",
                    background:
                        "linear-gradient(150deg, rgba(230,229,238,.7) 0%, rgba(255,255,255,1) 51%, rgba(255,255,255,.3) 100%)",
                    padding: 16,
                    boxShadow: "0px 0px 22px -15px rgba(0,0,0,0.6)",
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    backgroundColor: palette.common?.white,
                    // boxShadow: "0px 0px 22px -15px rgba(0,0,0,0.6)",
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 0px 22px -15px rgba(0,0,0,0.6)",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    backdropFilter: "blur(3px)",
                    background: "rgba(255,255,255,.1)",
                },
                container: {
                    background: "rgba(255,255,255,0.1)",
                },
                paper: {
                    borderRadius: 16,
                    background:
                        "linear-gradient(150deg, rgba(230,229,238,.7) 0%, rgba(255,255,255,1) 51%, rgba(255,255,255,.3) 100%)",
                    padding: 16,
                    boxShadow: "0px 0px 22px -15px rgba(0,0,0,0.6)",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: "8px !important",
                },
            },
        },
    },
})
