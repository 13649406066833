import { ButtonBase, styled, Typography } from "@mui/material"

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    height: 300,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderRadius: theme.spacing(2),
    //boxShadow: theme.shadows[2],
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.1), 0px 1px 7px 0px rgba(0,0,0,0.12)",
    transition: "all .5s",
    padding: 15,
    background: "white",

    //background: "linear-gradient(150deg, rgba(230,229,238,.7) 0%, rgba(255,255,255,1) 51%, rgba(255,255,255,.3) 100%)",
    [theme.breakpoints.down("sm")]: {
        width: "100% !important",
        height: 120,
    },
    "&:hover, &.Mui-focusVisible": {
        zIndex: 1,
        boxShadow: theme.shadows[5],

        "& .MuiImageBackdrop-root": {
            opacity: 0.1,
        },
        "& .MuiImageMarked-root": {
            opacity: 0,
        },
        "& .MuiTypography-root": {
            //border: "4px solid currentColor",
            color: theme.palette.primary.main,
        },
    },
}))

const ImageTitle = styled(Typography)(({ theme }) => ({
    position: "relative",
    padding: theme.spacing(2),
    fontWeight: "bold",
    // textShadow: "0 1px 2px rgba(0,0,0,1)",
    color: theme.palette.primary.dark,
    //background: "white",
    background: "rgba(255,255,255,.6)",

    width: "100%",
    fontSize: 17,
    borderRadius: "0 0 11px 11px"

}))

const ImageSrc = styled("div")({
    flex: 1,
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "11px 11px 0 0"
})

const ImageBackdrop = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    background: "linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.7))",
    transition: theme.transitions.create("opacity"),
    opacity: 0.2,
}))

interface ItemCardProps {
    onClick: (id: string) => void
    app: any
}

export function ItemCard({ onClick, app }: ItemCardProps) {
    return (
        <ImageButton
            focusRipple
            key={app.title}
            onClick={() => onClick(app.id)}
            style={{
                width: "100%",
            }}
        >
            <ImageSrc
                style={{
                    backgroundImage: `url(${app.image})`,
                }}
            />
            <ImageBackdrop className="MuiImageBackdrop-root" />

            <ImageTitle variant="h6">{app.title}</ImageTitle>
        </ImageButton>
    )
}
