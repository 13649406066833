import {
    Card,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material"
import { CheckCircle, XCircle } from "@phosphor-icons/react"
import { useTheme } from "@mui/material"
import { CDSItem, Multipart } from "../../../types"

interface ChecksProps {
    season?: Multipart
    episode?: CDSItem
}

export function Checks({ season, episode }: ChecksProps) {
    const theme = useTheme()
    if (!episode?.id) {
        return null
    }

    return (
        <Card sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%" }}>
                <Typography variant="h6" gutterBottom>
                    Checks
                </Typography>
                <List dense>
                    {Object.keys(episode.validation || {}).map((key) => (
                        <ListItem key={episode.id}>
                            <ListItemIcon>
                                {episode.validation?.[key]?.successfully ? (
                                    <CheckCircle
                                        color={theme.palette.success.main}
                                        size={28}
                                    />
                                ) : (
                                    <XCircle
                                        color={theme.palette.error.main}
                                        size={28}
                                    />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    episode.validation?.[key]?.title ||
                                    "Check nicht bekannt"
                                }
                            />
                        </ListItem>
                    ))}
                </List>
                {season && (
                    <>
                        <Divider />
                        <List dense>
                            {Object.keys(season?.validation || {}).map((key) => (
                                <ListItem key={season.title}>
                                    <ListItemIcon>
                                        {season.validation?.[key]?.successfully ? (
                                            <CheckCircle
                                                color={theme.palette.success.main}
                                                size={28}
                                            />
                                        ) : (
                                            <XCircle
                                                color={theme.palette.error.main}
                                                size={28}
                                            />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            season.validation?.[key]?.title ||
                                            "Check nicht bekannt"
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
            </CardContent>
        </Card>
    )
}
