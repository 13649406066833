import { useEffect, useState } from "react"
import { convertAudio } from "../../services/rest.service"

import "./style.css"
import { FileAudio } from "@phosphor-icons/react"

const FileConverter = () => {
    const [file, setFile] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        file && handleFileUpload()
        // eslint-disable-next-line
    }, [file])

    const handleFileChange = (event) => {
        setFile(event.target.files[0])
    }

    const handleFileUpload = async () => {
        setError(null)

        try {
            const formData = new FormData()
            formData.append("file", file)

            const response = await convertAudio(formData)
            console.log(response)
            // Extract filename from the content-disposition header
            const contentDisposition = response.headers["content-disposition"]
            const matches = contentDisposition.match(/filename="(.+?)"/)
            const originalFilename = matches[1]

            const convertedFileBlob = new Blob([response.data], {
                type: "audio/mpeg",
            })
            const convertedFileUrl = URL.createObjectURL(convertedFileBlob)

            const link = document.createElement("a")
            link.href = convertedFileUrl

            // Set the download filename to the original filename
            link.download = originalFilename

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

            // Clean up the object URL to avoid memory leaks
            URL.revokeObjectURL(convertedFileUrl)
        } catch (error) {
            console.error("Error uploading or downloading file:", error)
            setError(error)
        }
    }
    const handleDrop = (event) => {
        event.preventDefault()
        setFile(event.dataTransfer.files[0])
    }

    const preventDefaultAndStopPropagation = (event) => {
        event.preventDefault()
        event.stopPropagation()
    }

    return (
        <div className="audio-converter">
            <label
                onDrop={handleDrop}
                onDragOver={preventDefaultAndStopPropagation}
                onDragEnter={preventDefaultAndStopPropagation}
                className="drop"
            >
                <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                />
                <FileAudio size={40} />
                Ziehe eine Audio-Datei per Drag & Drop hierher oder klicke, um eine
                Datei auszuwählen.
            </label>
            {!!file && <p>Datei: {file.name}</p>}
            {!!error && (
                <pre style={{ color: "darkred" }}>
                    Datei konnte nicht konvertiert werden: {error?.mesage}
                </pre>
            )}
        </div>
    )
}

export function AudioConverterPage() {
    return <FileConverter />
}
