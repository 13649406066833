import {
    Backdrop,
    Card,
    CardContent,
    Grid,
    IconButton,
    styled,
    Typography,
    useTheme,
} from "@mui/material"
import { X } from "@phosphor-icons/react"

export const CloseButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(3.5),
    right: theme.spacing(3),
}))

interface UpdateErrorDialogProps {
    open: boolean
    onClose: () => void
}

export function UpdateErrorDialog({ open, onClose }: UpdateErrorDialogProps) {
    const theme = useTheme()

    return (
        <Backdrop open={open}>
            <Card sx={{ maxWidth: 500, position: "relative" }}>
                <CloseButton onClick={onClose}>
                    <X color={theme.palette.primary.dark} />
                </CloseButton>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                color={theme.palette.error.main}
                            >
                                Aktualisierung fehlgeschlagen
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Die Aktualisierung der Daten für den Skill ist
                                fehlgeschlagen.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Bitte wenden dich an das Voice Team!
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>voice@wdr.de</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Backdrop>
    )
}
