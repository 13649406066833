import { GridColDef, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid"
import { DataGrid } from "../../../components/DataGrid/DataGrid"
import { Grid, Tooltip, useTheme } from "@mui/material"
import { useContext, useEffect, useMemo, useState } from "react"
import ProductContext from "../../../contexts/ProductContext"
import { useNavigate, useSearchParams } from "react-router-dom"
import { CDSItem, Multipart } from "../../../types"
import { CheckCircle, XCircle } from "@phosphor-icons/react"
import { msToTime } from "../../../helper/strings"
import { ItemInformationDialog } from "../components/ItemInformationDialog"

export function MultiPartContainer() {
    const { productData, loadingProduct } = useContext(ProductContext)

    const theme = useTheme()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const id = searchParams.get("id")
    const isEpisodeSelected = searchParams.get("episodeSelected")

    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
    const [selectionEpisodeModel, setSelectionEpisodeModel] =
        useState<GridRowSelectionModel>([])
    const [selectedItem, setSelectedItem] = useState<Multipart | undefined>(
        undefined
    )
    const [selectedEpisode, setSelectedEpisode] = useState<CDSItem | undefined>(
        undefined
    )

    useEffect(() => {
        if (id && productData) {
            const multipart = productData?.multiparts?.filter(
                (m: Multipart) =>
                    m.items.filter((i: CDSItem) => i.id === id).length > 0
            )?.[0]

            setSelectedItem(multipart)
            setSelectionModel(multipart?.items?.[0]?.id)

            if (isEpisodeSelected) {
                setSelectedEpisode(
                    multipart?.items?.filter((i: CDSItem) => i.id === id)?.[0]
                )
                setSelectionEpisodeModel([id])
            }
        } else {
            setSelectionModel([productData?.multiparts?.[0]?.items?.[0]?.id])
            setSelectionEpisodeModel([productData?.multiparts?.[0]?.items?.[0]?.id])
            setSelectedItem(productData?.multiparts?.[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productData.multiparts])

    const handleItemClick = (params: GridRowParams) => {
        setSelectedItem(params.row)
        setSelectionEpisodeModel([])
        navigate(`?id=${params.id}`)
    }

    const handleEpisodeClick = (params: GridRowParams) => {
        setSelectedEpisode(params.row)
        navigate(`?id=${params.id}&episodeSelected=true`)
    }

    const handleDialogClose = () => {
        if (selectedEpisode) {
            navigate(`?id=${selectedEpisode.id}`)
        }

        setSelectedEpisode(undefined)
        setSelectionEpisodeModel([])
    }

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: "title",
                headerName: "Titel",
                renderCell: (params) => <b>{params.value}</b>,
                flex: 1,
            },
            {
                field: "errors",
                headerName: "Checks",
                width: 75,
                renderCell: (params) => {
                    return Object.keys(params?.row?.validation || {}).filter(
                        (key) => !params?.row?.validation?.[key].successfully
                    ).length > 0 ? (
                        <Tooltip title="Checks sind fehlgeschlagen">
                            <XCircle
                                size={25}
                                color={theme.palette.error.main}
                                style={{ marginLeft: 15 }}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip title="Alle Checks bestanden">
                            <CheckCircle
                                size={25}
                                color={theme.palette.success.main}
                                style={{ marginLeft: 15 }}
                            />
                        </Tooltip>
                    )
                },
            },
        ], // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const columnsEpisodes: GridColDef[] = useMemo(
        () => [
            {
                field: "season",
                headerName: "Staffel",
                width: 80,
            },
            {
                field: "episode",
                headerName: "Episode",
                width: 80,
            },
            {
                field: "smartSpeakerShort",
                headerName: "Teaser",
                flex: 1,
            },
            {
                field: "duration",
                headerName: "Dauer",
                width: 75,
                valueFormatter: (params) =>
                    params.value ? msToTime(params.value) : "",
            },
            {
                field: "errors",
                headerName: "Checks",
                width: 75,
                renderCell: (params) => {
                    return Object.keys(params?.row?.validation || {}).filter(
                        (key) => !params?.row?.validation?.[key].successfully
                    ).length > 0 ||
                        Object.keys(params?.row?.multipart?.validation || {}).filter(
                            (key) =>
                                !params?.row?.multipart?.validation?.[key]
                                    .successfully
                        ).length > 0 ? (
                        <Tooltip title="Checks sind fehlgeschlagen">
                            <XCircle
                                size={25}
                                color={theme.palette.error.main}
                                style={{ marginLeft: 15 }}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip title="Alle Checks bestanden">
                            <CheckCircle
                                size={25}
                                color={theme.palette.success.main}
                                style={{ marginLeft: 15 }}
                            />
                        </Tooltip>
                    )
                },
            },
        ], // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    if (loadingProduct) {
        return null
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <DataGrid
                    columns={columns}
                    rows={
                        productData?.multiparts?.map((m: Multipart) => ({
                            ...m,
                            id: m.items?.[0]?.id,
                        })) || []
                    }
                    onRowClick={handleItemClick}
                    onSelectionModelChange={(newSelectionModel) =>
                        setSelectionModel(newSelectionModel)
                    }
                    selectionModel={selectionModel}
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <DataGrid
                    autoRowHeight
                    columns={columnsEpisodes}
                    rows={
                        selectedItem?.items?.map((i) => ({
                            ...i,
                            multipart: selectedItem,
                        })) || []
                    }
                    onRowClick={handleEpisodeClick}
                    onSelectionModelChange={(newSelectionModel) =>
                        setSelectionEpisodeModel(newSelectionModel)
                    }
                    selectionModel={selectionEpisodeModel}
                />
            </Grid>
            <ItemInformationDialog
                season={selectedItem}
                episode={selectedEpisode}
                open={!!selectedEpisode}
                onClose={handleDialogClose}
            />
        </Grid>
    )
}
