import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { useContext, useEffect, useRef, useState } from "react"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import deLocale from "date-fns/locale/de"
import MediaSelectionCard from "./MediaSelectionCard"
import ModeToggleButton from "./ModeToggleButton"
import DynamicCard from "./DynamicCard"

import config from "./config/einslive"
import ProductContext from "../../contexts/ProductContext"
import { deleteSpotlight, upsertSpotlight } from "../../services/rest.service"
import { ssmlVerification } from "./InputActions"

const SPOTLIGHT_DELETED = "Spotlight gelöscht"
const SPOTLIGHT_SAVED = "Spotlight gespeichert"
const SPOTLIGHT_SAVE_ERROR = "Fehler beim Speichern: "

export const SpotlightForm = ({ inititalValues }) => {
    const [formValues, setFormValues] = useState(inititalValues ?? {})
    const [variant, setVariant] = useState(inititalValues?.variant ?? "audio")
    const [mode, setMode] = useState(inititalValues?.stage ?? "draft")
    const { product } = useContext(ProductContext)
    console.log("inititalValues", inititalValues)
    const [toast, setToast] = useState({ open: false, message: "" })

    const [first, setFirst] = useState(true)

    const handleClose = () => {
        setToast({ ...toast, open: false })
    }

    const [details, setDetails] = useState({
        title: inititalValues?.title ?? "",
        description: inititalValues?.description ?? "",
    })

    const formValuesRef = useRef(formValues)

    useEffect(() => {
        formValuesRef.current = formValues
    }, [formValues])

    useEffect(() => {
        let updatedValues = { ...formValuesRef.current }

        config.forEach((item) => {
            const v = item?.variant?.[variant]?.value
            if (v !== undefined && v !== null) {
                if (first) {
                    // exception
                    if (item.name === "videoOnly") updatedValues[item.name] = v
                    else
                        updatedValues[item.name] =
                            inititalValues[item.name] !== undefined
                                ? inititalValues[item.name]
                                : v
                } else {
                    updatedValues[item.name] = v
                }
            }
        })
        setFirst(false)
        setFormValues(updatedValues)
    }, [variant])

    // debug
    useEffect(() => {
        console.log("formValues", formValues)
    }, [formValues])
    //

    const handleDetailsChange = (event) => {
        setDetails({ ...details, [event.target.name]: event.target.value })
    }

    const groupedConfig = config.reduce((acc, item) => {
        acc[item.section] = acc[item.section] || []
        acc[item.section].push(item)
        return acc
    }, {})

    const save = async () => {

        const verificationResult = await ssmlVerification(`${formValues.offerSsml} ${formValues.hintSsml}` ,setToast)

        if(verificationResult?.error) return

        try {
            await upsertSpotlight({
                ...formValues,
                id: inititalValues.id,
                title: details.title,
                description: details.description,
                stage: mode,
                variant,
                skill: product.toLowerCase(),
            })
            setToast({ open: true, message: SPOTLIGHT_SAVED })
        } catch (e) {
            setToast({ open: true, message: SPOTLIGHT_SAVE_ERROR + e?.message })
            console.error(e)
        }
    }

    const remove = async () => {
        await deleteSpotlight(inititalValues.id)
        setToast({ open: true, message: SPOTLIGHT_DELETED })

        setTimeout(() => {
            window.location.href = "/" + product.toLowerCase() + "/spotlights/:id"
        }, 2000)
    }

    return (
        <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                <Card>
                    <CardContent>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant="h6">
                                Spotlight bearbeiten
                            </Typography>
                            <span style={{ fontSize: "small" }}>
                                ID: {inititalValues.id}
                            </span>
                            <ModeToggleButton onChange={setMode} value={mode} />
                        </Box>
                        <TextField
                            fullWidth
                            margin="normal"
                            name="title"
                            label="Titel"
                            type="text"
                            value={details.title}
                            onChange={handleDetailsChange}
                            helperText="Bei Displaygeräten: Angezeigter Titel des Spotlight"
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            name="description"
                            label="Beschreibung"
                            type="text"
                            value={details.description}
                            onChange={handleDetailsChange}
                            helperText="Bei Displaygeräten: Angezeigter Beschreibungstext"
                        />
                    </CardContent>
                </Card>

                <MediaSelectionCard variant={variant} setVariant={setVariant} />
                {Object.keys(groupedConfig).map((section) => (
                    <DynamicCard
                        key={section}
                        config={groupedConfig[section]}
                        variant={variant}
                        formValues={formValues}
                        setFormValues={setFormValues}
                    />
                ))}
                <Box paddingY={"20px"}>
                    {
                        <Button
                            onClick={remove}
                            variant="contained"
                            color="error"
                            disabled={mode !== "draft"}
                            title={mode !== "draft" && "Bitte zuerst Stage ändern"}
                        >
                            Löschen
                        </Button>
                    }

                    <Button
                        onClick={save}
                        variant="contained"
                        style={{ float: "right" }}
                    >
                        Speichern
                    </Button>
                    <Button
                        onClick={() => {
                            window.location.href =
                                "/" + product.toLowerCase() + "/spotlights/:id"
                        }}
                        style={{ float: "right", marginRight: "10px" }}
                    >
                        Abbrechen
                    </Button>
                </Box>

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    open={toast.open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={toast.message}
                />
            </LocalizationProvider>
        </Grid>
    )
}
