import React from 'react';
import { Card,  Radio, Typography, CardContent, FormControlLabel, useTheme } from '@mui/material';
import {options} from "./config/variants"

export default function MediaSelectionCard({variant, setVariant}) {
  const theme = useTheme();

  const handleChange = (value) => {
    setVariant(value);
  };

  return (
    <div
    style={{justifyContent: "space-evenly",
      display: "flex", gap: 10,
      width: "100%", marginTop: 20}}
    >
      {options.map(({ value, label, Icon }) => (
        <Card  key={value} sx={{  width: 280,  cursor: "pointer", position: "relative", boxSizing: "border-box" , outline: variant===value ? "4px solid #0260ac" : undefined }}  onClick={() => handleChange(value)}>
            <CardContent>
              <Icon color={theme.palette.primary.main}  style={{ fontSize: 60, display: 'block', margin: 'auto' , marginBottom: "10px" }} />
              <Typography variant="h6" component="div" align="center" >
                {label}
              </Typography>
              <FormControlLabel
                control={<Radio checked={variant === value} sx={
                  {
                    color: '#ddd', 
                    '&.Mui-checked': {
                      color:  '#0260ac'
                    },
                  }
                } />}
                label=""
                style={{ position: "absolute", left: "30px", top: "20px" }}
              />
            </CardContent>
        </Card>
      ))}
    </div>
  );
}
