import {
    Avatar,
    Card,
    CardContent,
    Chip,
    Grid,
    styled,
    Typography,
    useTheme,
} from "@mui/material"
import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import ProductContext from "../../contexts/ProductContext"
import { applySinglePartFilter } from "../../helper/hss"
import { isCDSItemValid, isValid } from "../../helper/checks"
import { getAmountOfTags } from "../../helper/tags"
import { Multipart } from "../../types"
import { ErrorContainer } from "./components/ErrorContainer"
import { HistoryContainer } from "./components/HistoryContainer"
import { useCookies } from "react-cookie"

export const ChipContainer = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
        margin: theme.spacing(0.5),
    },
}))

const CustomCard = styled(Card)({
    textAlign: "center",
})

export function HssContainer() {
    const [cookies] = useCookies(["user", "token"])
    const navigate = useNavigate()
    useEffect(() => {
        const user = cookies.user
        const token = cookies.token
        if (!token && !user) {
            navigate("/")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate])

    const theme = useTheme()
    const { productData } = useContext(ProductContext)

    const singleParts = applySinglePartFilter(productData?.singleparts, {})
    const multiParts = applySinglePartFilter(
        productData?.multiparts,
        {}
    ) as unknown as Multipart[]

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <CustomCard>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="h2">
                                    {singleParts?.length || 0}
                                </Typography>
                                <Typography>Einteiler</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        color:
                                            singleParts?.filter(
                                                (singlePart) =>
                                                    !isCDSItemValid(singlePart)
                                            ).length === 0
                                                ? theme.palette.success.main
                                                : theme.palette.error.main,
                                    }}
                                >
                                    {singleParts?.filter(
                                        (singlePart) => !isCDSItemValid(singlePart)
                                    ).length || 0}
                                </Typography>
                                <Typography>Fehler</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CustomCard>
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomCard>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="h2">
                                    {multiParts?.length || 0}
                                </Typography>
                                <Typography>Mehrteiler</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        color:
                                            multiParts?.filter(
                                                (multiPart) => !isValid(multiPart)
                                            ).length === 0
                                                ? theme.palette.success.main
                                                : theme.palette.error.main,
                                    }}
                                >
                                    {multiParts?.filter(
                                        (multiPart) => !isValid(multiPart)
                                    ).length || 0}
                                </Typography>
                                <Typography>Fehler</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CustomCard>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Tags der Einteiler
                        </Typography>
                        <ChipContainer>
                            {Object.keys(getAmountOfTags(singleParts)).map((key) => (
                                <Chip
                                    key={key}
                                    label={key}
                                    avatar={
                                        <Avatar>
                                            {getAmountOfTags(singleParts)[key]}
                                        </Avatar>
                                    }
                                />
                            ))}
                        </ChipContainer>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <HistoryContainer />
            </Grid>
            <Grid item xs={12} md={6}>
                <ErrorContainer />
            </Grid>
        </Grid>
    )
}
