import { verifySsml } from "../../services/rest.service"

const ERROR_MESSAGES = [
    "Ich kann mich mit der URI der Audiodatei nicht verbinden.",
    "Bei der Antwort des angeforderten Skill ist ein Problem aufgetreten",
]

export const testSSML = async (ssml) => {
    const result = await verifySsml(ssml)
    console.log(result)

    let content
    try {
        content =result.result.alexaExecutionInfo.alexaResponses[0].content.caption
    } catch {
        content = "Kein Inhalt in der Antwort vorhanden"
    }
    let error = false
    if (ERROR_MESSAGES.includes(content)) {
        error = true
    }
    if (result?.status !== "SUCCESSFUL") {
        error = true
    }
    return { error, content }
}