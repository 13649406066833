import client, {
    converterClient,
    pushClient,
    spotlightClient,
    ssmlClient,
} from "../configs/axios.config"
import {
    AlexaSkillVersionData,
    SkillNotification,
    SkillNotificationResponse,
} from "../types"

export let axiosController: any = undefined

export async function fetchErrorLogs(voiceApp: string) {
    axiosController = new AbortController()
    if (!voiceApp) {
        return null
    }

    return client
        .get(`/errors/${voiceApp.toLowerCase()}`, {
            signal: axiosController.signal,
        })
        .then((res) => res.data)
}

export async function fetchConfigByVoiceApp(voiceApp: string) {
    if (!voiceApp) {
        return null
    }

    return client.get(`/config/${voiceApp.toLowerCase()}`).then((res) => res.data)
}

export async function fetchDataByProduct(product: string) {
    if (!product) {
        return null
    }

    return client.get(`/${product.toLowerCase()}`).then((res) => res.data)
}

export async function updateDataByProduct(
    product: string
): Promise<{ success: boolean }> {
    return client
        .post(`/update`, { voiceApp: product.toUpperCase() })
        .then((res) => ({ success: true }))
        .catch((err) => {
            console.error("Could not update data", err)
            return {
                success: false,
            }
        })
}

export async function getStatusByProduct(
    product: string
): Promise<{ data: { message: string; status: string; progress: number } }> {
    return client
        .get(`/status/${product.toUpperCase()}`)
        .then((res) => res.data)
        .catch((err) => err)
}

export async function fetchSearchLogsByProduct(product: string) {
    if (!product) {
        return null
    }

    return client
        .get(`/${product.toLowerCase()}/search-logs`)
        .then((res) => res.data)
        .catch((e) => {
            console.error(e)
        })
}

export async function fetchPushNotifications(
    voiceProductAccessToken: string
): Promise<SkillNotificationResponse> {
    return pushClient
        .get(`/events?voiceProductAccessToken=${voiceProductAccessToken}`)
        .then((res) => res.data)
}

export async function createNotification(
    voiceProductAccessToken: string,
    notification: SkillNotification
) {
    return pushClient
        .post(`/events?voiceProductAccessToken=${voiceProductAccessToken}`, {
            events: [notification],
        })
        .then((res) => res.data)
}

export async function updateNotification(
    voiceProductAccessToken: string,
    notification: SkillNotification
) {
    return pushClient
        .put(
            `/events/${notification.id}?voiceProductAccessToken=${voiceProductAccessToken}`,
            { event: notification }
        )
        .then((res) => res.data)
}

export async function deleteNotification(
    voiceProductAccessToken: string,
    notification: SkillNotification
) {
    return pushClient
        .delete(
            `/events/${notification.id}?voiceProductAccessToken=${voiceProductAccessToken}`
        )
        .then((res) => res.data)
}

export async function validateAccessToken(voiceProductAccessToken: string) {
    return pushClient
        .post(`/validate?voiceProductAccessToken=${voiceProductAccessToken}`)
        .then((res) => res.data)
}

export async function fetchVersions(): Promise<AlexaSkillVersionData[]> {
    return client.get("/versions").then((res) => res.data)
}

export async function updateVersionRoute(
    skillId: string,
    targetArn: string
): Promise<void> {
    await client.put(`/versions/${skillId}`, { targetArn })
}

export async function convertAudio(formData: any) {
    return converterClient.post(`/convert`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        responseType: "arraybuffer",
    })
}

export async function verifySsml(ssml: string) {
    return ssmlClient.post(`/simulate/`, { ssml }).then((res) => res.data)
}


export async function fetchSpotlightsBySkill(skill: string): Promise<any> {
    return spotlightClient.get(`/skill/${skill}`).then((res) => res.data)
}

export async function fetchSpotlight(id: string): Promise<any> {
    return spotlightClient.get(`/${id}`).then((res) => res.data)
}

export async function upsertSpotlight(data: any): Promise<any> {
    return spotlightClient.post(`/`, data).then((res) => res.data)
}

export async function deleteSpotlight(id: string): Promise<any> {
    return spotlightClient.delete(`/${id}`).then((res) => res.data)
}
