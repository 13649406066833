import { useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import ProductContext from "../../contexts/ProductContext"
import { CDSItem } from "../../types"
import { DataGrid } from "../../components/DataGrid/DataGrid"
import { GridColDef, GridRowParams } from "@mui/x-data-grid"
import { Tooltip, useTheme } from "@mui/material"
import { CheckCircle, XCircle } from "@phosphor-icons/react"
import { CandidateInformationDialog } from "./components/CandidateInformationDialog"
import { useCookies } from "react-cookie"

export function KandidatenCheckContainer() {
    const theme = useTheme()
    const { productData, loadingProduct } = useContext(ProductContext)

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const id = searchParams.get("id")

    const [selectedItem, setSelectedItem] = useState<CDSItem | undefined>(undefined)

    useEffect(() => {
        if (productData && productData.length > 0) {
            const item = productData.filter((s: CDSItem) => s.id === id)?.[0]
            setSelectedItem(item)
        }
    }, [productData, id])

    const [cookies] = useCookies(["user", "token"])
    useEffect(() => {
        const user = cookies.user
        const token = cookies.token
        if (!token && !user) {
            navigate("/")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate])

    const handleItemClick = (params: GridRowParams) => {
        setSelectedItem(params.row)
        navigate(`?id=${params.id}`)
    }

    const handleDialogClose = () => {
        setSelectedItem(undefined)
        navigate(``)
    }

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: "firstname",
                headerName: "Vorname",
                flex: 1,
            },
            {
                field: "lastname",
                headerName: "Nachname",
                flex: 1,
            },
            {
                field: "wahlkreis",
                headerName: "Wahlkreis",
                flex: 1,
            },
            {
                field: "party",
                headerName: "Partei",
                flex: 1,
            },
            {
                field: "validation",
                headerName: "Checks",
                width: 75,
                renderCell: (params) => {
                    return Object.keys(params?.value || {}).filter(
                        (key) => !params?.value?.[key].successfully
                    ).length > 0 ? (
                        <Tooltip title="Checks sind fehlgeschlagen">
                            <XCircle
                                size={25}
                                color={theme.palette.error.main}
                                style={{ marginLeft: 15 }}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip title="Alle Checks bestanden">
                            <CheckCircle
                                size={25}
                                color={theme.palette.success.main}
                                style={{ marginLeft: 15 }}
                            />
                        </Tooltip>
                    )
                },
            },
        ],
        [theme]
    )

    if (loadingProduct) {
        return null
    }

    return (
        <>
            <DataGrid
                rows={productData}
                columns={columns}
                onRowClick={handleItemClick}
            />
            <CandidateInformationDialog
                candidate={selectedItem}
                open={!!selectedItem}
                onClose={handleDialogClose}
            />
        </>
    )
}
