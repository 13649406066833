import { Route, Routes, useLocation } from "react-router-dom"
import { SelectionPage } from "./pages/SelectionPage"
import { useContext, useEffect } from "react"
import ProductContext from "./contexts/ProductContext"
import { url } from "./configs/loginConfig"
import { CookiesProvider, useCookies } from "react-cookie"
import {
    fetchConfigByVoiceApp,
    fetchDataByProduct,
    fetchErrorLogs,
} from "./services/rest.service"
import { VOICE_APPS } from "./configs/voiceApps"
import { AdministrationPage } from "./pages/administration/AdministrationContainer"
import { CognitoJwtVerifier } from "aws-jwt-verify"
import { JwtInvalidClaimError } from "aws-jwt-verify/error"
import { Layout } from "./components/Layout/Layout"
import { AudioConverterPage } from "./pages/audioConverter/AudioConverterContainer"
import { SSMLTestPage } from "./pages/ssmlTest/SSMLTestContainer"

function App() {
    /**
     * The following Verifiers will be used to verify the Identity of the user and the access token sent from AWS Cognito,
     * before allowing the user to continue callig the APIs:
     */

    /**
     * Identity_verifier, will check the id_token sent from AWS Cognito and extract the info of the user from the token like name and email.
     * */
    const identity_verifier = CognitoJwtVerifier.create({
        userPoolId: "eu-central-1_sicIgX6IP",
        tokenUse: "id",
        clientId: "3m49dlkgs9b1k1pbb1mm0fvujp",
    })
    /**
     * Access_verifier will check the access token sent from AWS Cognito and then this token will
     * injected in the Header of all request towards our APIs.
     */

    const access_verifier = CognitoJwtVerifier.create({
        userPoolId: "eu-central-1_sicIgX6IP",
        tokenUse: "access",
        clientId: "3m49dlkgs9b1k1pbb1mm0fvujp",
    })

    const location = useLocation()

    const [cookies, setCookie] = useCookies(["user", "token", "groups"])
    const urlParameters = new URLSearchParams(location.hash)
    const {
        setProductData,
        setLoadingProduct,
        setConfig,
        setErrorLogs,
        setProduct,
    } = useContext(ProductContext)

    useEffect(() => {
        ;(async () => {
            const id_token = urlParameters.get("#id_token")
            const access_token = urlParameters.get("access_token")
            let authenticated = false
            if (id_token && access_token) {
                try {
                    await access_verifier.verify(access_token)
                    const payload_id = await identity_verifier.verify(id_token)
                    const groups = payload_id["cognito:groups"]
                    const email = payload_id.email
                    const name = payload_id.name
                    const user = name + " (" + email + ")"
                    authenticated = true
                    setCookie("user", user, { path: "/" })
                    setCookie("token", id_token, { path: "/" })
                    if (groups) {
                        setCookie("groups", groups, { path: "/" })
                    } else {
                        setCookie("groups", "Dev", { path: "/" })
                    }

                    window.sessionStorage.setItem(
                        "token",
                        JSON.stringify(access_token)
                    )
                    window.sessionStorage.setItem("groups", JSON.stringify(groups))
                } catch (err) {
                    if (err instanceof JwtInvalidClaimError) {
                        console.error("JWT invalid because:", err.message)
                        //console.error("Raw JWT:", err.rawJwt.payload)
                    }
                    //setAuthenticated(false)
                    authenticated = false
                    throw new Error("Unauthorized")
                }
            } else {
                const userStoredInCookie = cookies.user
                const tokenStoredInCookie = cookies.token
                const groupsStoredInCookie = cookies.groups
                if (
                    userStoredInCookie &&
                    tokenStoredInCookie &&
                    groupsStoredInCookie
                ) {
                    authenticated = true
                }
            }

            console.log(authenticated)
            if (!authenticated) {
                window.location.href = url
            }
        })()

        async function fetch() {
            if (location.pathname?.includes("administration")) {
                return
            }
            if (location.pathname?.includes("audioConverter")) {
                return
            }
            if (location.pathname?.includes("ssmlTest")) {
                return
            }

            setLoadingProduct(true)
            const loc = location.pathname?.replace("/", "")
            const id = loc?.slice(
                0,
                loc.includes("/") ? loc.indexOf("/") : loc.length
            )

            setProduct(id?.toUpperCase())

            const data = await fetchDataByProduct(id)
            const config = await fetchConfigByVoiceApp(id)
            const errorLogs = await fetchErrorLogs(id)

            console.log(id, data, config, errorLogs)

            if (data) {
                setProductData(data)
            }

            if (config) {
                setConfig(config)
            }

            if (errorLogs) {
                setErrorLogs(errorLogs)
            }

            setLoadingProduct(false)
        }

        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <CookiesProvider>
            {!cookies.user || !cookies.token ? null : (
                <Layout>
                    <Routes>
                        <Route path="/" element={<SelectionPage />} />
                        <Route
                            path="/administration"
                            element={<AdministrationPage />}
                        />
                        <Route
                            path="/audioConverter"
                            element={<AudioConverterPage />}
                        />
                        <Route path="/ssmlTest" element={<SSMLTestPage />} />
                        {VOICE_APPS?.map((app) =>
                            app.routes?.map((route) => (
                                <Route
                                    key={route.path}
                                    path={`/${app.id?.toLowerCase()}${route.path}`}
                                    element={route.component}
                                />
                            ))
                        )}
                    </Routes>
                </Layout>
            )}
        </CookiesProvider>
    )
}

export default App
