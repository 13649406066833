/* eslint-disable import/no-anonymous-default-export */
export default [
    {
        name: "contentId",
        label: "CDS Programm-ID",
        type: "text",
        description:
            "Programm-ID der Show lt. Content Delivery Service. Item wird in Mediathek während verfügbarem Zeitraum angezeigt.",
        section: "content",
        variant: {
            video: { hidden: true, value: null },
        },
    },
    {
        name: "videoNode",
        label: "Video-Node",
        type: "text",
        description:
            "Bei Displaygeräten: Alternativer Strukturknoten für Videoinhalte",
        section: "content",
        variant: {
            audio: { hidden: true, value: null },
            video: { hidden: true, value: null },
        },
    },
    {
        name: "videoUrl",
        label: "Video Stream-URL",
        type: "text",
        description:
            "Link zum Video (live oder onDemand - kein Youtube Link!). Spotlight wird nur auf Displaygeräten vorgeschlagen und ausgespielt",
        section: "content",
        variant: {
            audio: { hidden: true, value: null },
            audioVideo: { hidden: true, value: null },
        },
    },

    {
        name: "offerSsml",
        label: "Hinweis",
        type: "text",
        description: "Ausgespielter Text, Audio oder SSML",
        section: "offer",
        check: "ssml",
    },
    {
        name: "offerFreq",
        label: "Angebotsanzahl",
        type: "text",
        description:
            "Gibt an, bei wie vielen Starts das Spotlight angeboten werden soll. Falls nicht angegeben, wird es bei jedem Start im festgelegten Zeitraum vorgeschlagen.",
        section: "offer",
        variant: {
            audio: { value: "1" },
            audioVideo: { value: "1" },
        },
    },
    {
        name: "offerFrom",
        label: "Zeitraum von",
        type: "date",
        optional: true,
        description:
            "Gibt an, ab wann das Spotlight angeboten werden soll. Falls nicht angegeben, gilt es ab Liveschaltung.",
        section: "offer",
    },
    {
        name: "offerUntil",
        label: "Zeitraum bis",
        type: "date",
        optional: true,
        description:
            "Gibt an, bis wann das Spotlight angeboten werden soll. Falls nicht angegeben, gilt es bis die Angebotsanazahl erreicht ist.",
        section: "offer",
    },
    {
        name: "hintSsml",
        label: "Abschließender Hinweis",
        type: "text",
        description:
            "Einmaliger Hinweis, falls maximale Angebotsanzahl erreicht und abgelehnt wurde (mögliche Eingabe: Text, Audio-Link oder SSML).",
        section: "offer",
        check: "ssml",
    },
    {
        name: "image",
        label: "Bild",
        type: "text",
        description:
            "Bei Displaygeräten: Angezeigtes Coverbild (1:1). Fallback auf Coverbild des Media-Objekts oder Platzhalter, falls nicht angegeben.",
        section: "custom",
        check: "image",
    },
    {
        name: "customBackground",
        label: "Benutzerdefiniertes Hintergrundbild",
        type: "text",
        description: "Bei Displaygeräten: Benutzerdefiniertes Hintergrundbild",
        section: "custom",
        check: "image",
    },
    {
        name: "offerAlreadyPlayed",
        label: "Immer vorschlagen",
        type: "boolean",
        description:
            "Angebot auch ausspielen falls bereits angenommen. Limitiert durch Angebotsanzahl, falls gesetzt",
        section: "custom",
        variant: {
            audio: { value: false },
            audioVideo: { value: false },
            video: { value: true },
        },
    },
    {
        name: "limited",
        label: `"Nur bis" anzeigen`,
        type: "boolean",
        description: 'Bei Displaygeräten: Anzeige von Text "Nur bis: (Zeitraum bis)"',
        section: "custom",
        audio: { value: true },
        audioVideo: { value: true },
        video: { value: false },
    },
    {
        name: "live",
        label: `"Jetzt live" anzeigen`,
        type: "boolean",
        description:
            'Bei Displaygeräten: Anzeige von Text: "Jetzt live" statt "Jetzt neu"',
        section: "custom",
        variant: {
            audio: { value: false },
            audioVideo: { value: false },
            video: { value: true },
        },
    },
    {
        name: "videoOnly",
        label: "Nur Video",
        type: "boolean",
        description:
            "Bei Displaygeräten: Videoinhalt wird nur bei Displaygeräten angeboten",
        section: "custom",
        variant: {
            audio: { value: false, hidden: true },
            audioVideo: { value: false, hidden: true },
            video: { value: true, hidden: true },
        },
    },

    {
        name: "keywords",
        label: "Suchbegriffe",
        type: "text-array",
        description: "Suchbegriffe für das direkte Aufrufen der Inhalte (Mit Enter bestätigen)",
        section: "custom",
    },
]
