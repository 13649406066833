import { useContext, useEffect, useMemo, useState } from "react"
import ProductContext from "../../contexts/ProductContext"
import { Button, Card, CardContent, Grid, Typography } from "@mui/material"
import { fetchVersions, updateVersionRoute } from "../../services/rest.service"
import { AlexaSkillStageColumnData, AlexaSkillVersionData } from "../../types"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { format } from "date-fns"
import { styles } from "../../components/DataGrid/DataGrid"
import { SelectVersionDialog } from "./components/SelectVersionDialog"
import { useCookies } from "react-cookie"

export function AdministrationPage() {
    const [cookies] = useCookies(["user", "token", "groups"])
    const { setLoadingProduct } = useContext(ProductContext)

    const [versions, setVersions] = useState<AlexaSkillVersionData[]>([])
    const [selectedStage, setSelectedStage] =
        useState<AlexaSkillStageColumnData | null>(null)
    const [versionLoading, setVersionLoading] = useState(false)

    const getStageColumns = useMemo(() => {
        const columns: GridColDef[] = [
            {
                field: "id",
                headerName: "Umgebung",
                align: "left",
                flex: 1,
                renderCell: (params) => {
                    return (
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://developer.amazon.com/alexa/console/ask/${
                                params.value === "prod" ? "measure" : "test"
                            }/${params.row.skillId}/${
                                params.value === "prod" ? "live" : "development"
                            }/de_DE`}
                        >
                            {params.value}
                        </a>
                    )
                },
            },
            {
                field: "version",
                headerName: "Aktuelle Version",
                align: "left",
                flex: 1,
                renderCell: (params) => {
                    return (
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://eu-central-1.console.aws.amazon.com/lambda/home?region=eu-central-1#/functions/${params.row.targetArn}`}
                        >
                            {params.value}
                        </a>
                    )
                },
            },
            {
                field: "date",
                headerName: "Aktiv seit",
                align: "left",
                flex: 1,
            },
            {
                field: "actions",
                headerName: "",
                align: "left",
                flex: 1,
                renderCell: (params) => {
                    return (
                        <Button
                            variant="text"
                            size="small"
                            onClick={() => handleChangeVersionClick(params.row)}
                        >
                            Version ändern
                        </Button>
                    )
                },
            },
        ]
        return columns
    }, [])

    const getVersionColumns = useMemo(() => {
        const columns: GridColDef[] = [
            {
                field: "id",
                headerName: "Version",
                align: "left",
                flex: 1,
                renderCell: (params) => {
                    return (
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://eu-central-1.console.aws.amazon.com/lambda/home?region=eu-central-1#/functions/${params.row.targetArn}`}
                        >
                            {params.value}
                        </a>
                    )
                },
            },
            {
                field: "date",
                headerName: "Erstellt",
                align: "left",
                flex: 1,
                valueFormatter: (params) =>
                    params.value
                        ? format(new Date(params.value), "dd.MM.yyy HH:mm")
                        : "",
            },
        ]
        return columns
    }, [])

    useEffect(() => {
        async function fetch() {
            const versions = await fetchVersions()
            setVersions(versions)
            setLoadingProduct(false)
        }

        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getStageData(data: AlexaSkillVersionData) {
        const returnData: AlexaSkillStageColumnData[] = []

        Object.keys(data.stages)
            .sort((a, b) => a.localeCompare(b))
            .map((key) => {
                const currentVersion = data.versions?.filter(
                    (v) => v.targetArn === data.stages[key].targetArn
                )?.[0]

                returnData.push({
                    id: key,
                    date: data.stages[key].date
                        ? format(new Date(data.stages[key].date), "dd.MM.yyyy HH:mm")
                        : "",
                    version: currentVersion?.id || "",
                    targetArn: currentVersion?.targetArn || "",
                    skillId: data.stages[key].skillId,
                    voiceId: data.id,
                })
                return returnData
            })

        return returnData
    }

    const handleChangeVersionClick = (row: AlexaSkillStageColumnData) => {
        setSelectedStage(row)
    }

    const handleDialogClose = () => {
        setSelectedStage(null)
    }

    const handleSubmit = async (skillId: string, targetArn: string) => {
        if (!skillId || !targetArn) {
            return
        }

        setVersionLoading(true)

        console.log(skillId, targetArn)
        try {
            await updateVersionRoute(skillId, targetArn)
            const versions = await fetchVersions()
            setVersions(versions)
        } catch (e) {}

        setVersionLoading(false)
        handleDialogClose()
    }

    return (
        <Grid container spacing={2}>
            {cookies.groups.indexOf("Admins") > -1
                ? versions?.map((data) => (
                      <Grid item xs={12} key={data.id}>
                          <Card>
                              <CardContent>
                                  <Grid container spacing={2}>
                                      <Grid item xs={12}>
                                          <Typography variant="h6">
                                              {
                                                  // TODO: Workaround
                                                  `${data.id} ${
                                                      data.name
                                                          ? `(${data.name})`
                                                          : ""
                                                  }`
                                              }
                                          </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                          <Typography>Umgebungen</Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                          <Typography>
                                              Verfügbare Versionen
                                          </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                          <DataGrid
                                              sx={styles.root}
                                              autoHeight
                                              columns={getStageColumns}
                                              rows={getStageData(data)}
                                              disableRowSelectionOnClick
                                              hideFooter
                                          />
                                      </Grid>
                                      <Grid item xs={6}>
                                          <DataGrid
                                              sx={styles.root}
                                              autoHeight
                                              columns={getVersionColumns}
                                              rows={data?.versions}
                                              disableRowSelectionOnClick
                                              hideFooter
                                          />
                                      </Grid>
                                  </Grid>
                              </CardContent>
                          </Card>
                      </Grid>
                  ))
                : "Sie sind leider nicht berechtigt, den Inhalt dieser Seite zu sehen!"}
            <SelectVersionDialog
                versions={
                    versions?.filter((v) => v.id === selectedStage?.voiceId)?.[0]
                        ?.versions || []
                }
                stage={selectedStage}
                open={!!selectedStage}
                onClose={handleDialogClose}
                onSubmit={handleSubmit}
                loading={versionLoading}
            />
        </Grid>
    )
}
