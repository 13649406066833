import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

export default function ModeToggleButton({onChange, value }) {


  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(e,v) => onChange(v)}
      aria-label="Modus"
      color="primary"
    >
      <ToggleButton value="live" aria-label="Live" color="success">
        Live
      </ToggleButton>
      <ToggleButton value="test" aria-label="Test">
        Test
      </ToggleButton>
      <ToggleButton value="draft" aria-label="Entwurf">
        Entwurf
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
