import { useContext, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { SinglePartDataGrid } from "../hss/einteiler/components/SinglePartDataGrid"
import ProductContext from "../../contexts/ProductContext"
import { ItemInformationDialog } from "../hss/components/ItemInformationDialog"
import { CDSItem } from "../../types"

interface RockpalastItemsProps {
    type: string
}

export function RockpalastItems({ type }: RockpalastItemsProps) {
    const navigate = useNavigate()

    const { productData, loadingProduct } = useContext(ProductContext)
    const filteredProductData = Array.isArray(productData)
        ? productData?.filter((item) => item.filterTags?.includes(type))
        : []

    const [searchParams] = useSearchParams()
    const id = searchParams.get("id")

    const [selectedItem, setSelectedItem] = useState<CDSItem | undefined>(undefined)

    useEffect(() => {
        if (filteredProductData) {
            const item = filteredProductData.filter((s: CDSItem) => s.id === id)?.[0]
            setSelectedItem(item)
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredProductData])

    const handleItemClick = (item: CDSItem) => {
        setSelectedItem(item)
        navigate(`?id=${item.id}`)
    }

    const handleDialogClose = () => {
        setSelectedItem(undefined)
        navigate(``)
    }

    if (loadingProduct) {
        return null
    }

    return (
        <>
            <SinglePartDataGrid
                singleParts={filteredProductData}
                onItemClick={handleItemClick}
            />
            <ItemInformationDialog
                imageOnTop
                episode={selectedItem}
                open={!!selectedItem}
                onClose={handleDialogClose}
            />
        </>
    )
}
