import {
    Backdrop,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    styled,
    Typography,
} from "@mui/material"
import { AlexaSkillStageColumnData, AlexaSkillVersion } from "../../../types"
import { styles } from "../../../components/DataGrid/DataGrid"
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid"
import { useMemo, useState } from "react"
import { format } from "date-fns"
import { drawerWidth } from "../../../components/Layout/Layout"
import { CheckCircle } from "@phosphor-icons/react"

const ActionBarWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "end",
    marginTop: theme.spacing(2),
}))

interface SelectVersionDialogProps {
    versions: AlexaSkillVersion[]
    stage: AlexaSkillStageColumnData | null
    open: boolean
    onClose: () => void
    onSubmit: (skillId: string, targetArn: string) => void
    loading: boolean
}

export function SelectVersionDialog({
    versions,
    stage,
    open,
    onClose,
    onSubmit,
    loading,
}: SelectVersionDialogProps) {
    const [rowSelectionModel, setRowSelectionModel] =
        useState<GridRowSelectionModel>()
    const getVersionColumns = useMemo(() => {
        const columns: GridColDef[] = [
            {
                field: "action",
                headerName: "Wählen...",
                flex: 1,
                renderCell: (params) => {
                    console.log(stage?.version, params.id)
                    return (
                        <>
                            <IconButton
                                color={
                                    stage?.version === params.id
                                        ? "success"
                                        : "default"
                                }
                            >
                                <CheckCircle />
                            </IconButton>
                            {stage?.version === params.id && (
                                <Typography
                                    variant="caption"
                                    sx={{ color: "#519175", marginTop: 0.5 }}
                                >
                                    Aktuelle Version
                                </Typography>
                            )}
                        </>
                    )
                },
            },
            {
                field: "id",
                headerName: "Version",
                align: "left",
                flex: 1,
                renderCell: (params) => {
                    return (
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://eu-central-1.console.aws.amazon.com/lambda/home?region=eu-central-1#/functions/${params.row.targetArn}`}
                        >
                            {params.value}
                        </a>
                    )
                },
            },
            {
                field: "date",
                headerName: "Erstellt",
                align: "left",
                flex: 1,
                valueFormatter: (params) =>
                    params.value
                        ? format(new Date(params.value), "dd.MM.yyy HH:mm")
                        : "",
            },
        ]
        return columns
    }, [stage])

    const handleSubmit = () => {
        if (!rowSelectionModel?.length) {
            return
        }

        const selectedVersion = rowSelectionModel[0]
        const selectedTargetArn = versions?.filter(
            (v) => v.id === selectedVersion
        )?.[0]?.targetArn

        onSubmit(stage?.skillId || "", selectedTargetArn)
    }

    return (
        <Backdrop open={open} style={{ marginLeft: drawerWidth, padding: 32 }}>
            <Card sx={{ maxWidth: 700 ,
                maxHeight: "100%",
                overflow: "auto",
                marginTop: "100px"
            
            }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Version ändern <b>[{stage?.id}]</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <DataGrid
                                sx={styles.root}
                                autoHeight
                                columns={getVersionColumns}
                                rows={versions}
                                hideFooter
                                onRowSelectionModelChange={(
                                    newRowSelectionModel
                                ) => {
                                    setRowSelectionModel(newRowSelectionModel)
                                }}
                                rowSelectionModel={rowSelectionModel}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ActionBarWrapper>
                                <Button onClick={onClose} color="inherit">
                                    Abbrechen
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={handleSubmit}
                                    disabled={!rowSelectionModel}
                                >
                                    Speichern
                                </Button>
                            </ActionBarWrapper>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Backdrop>
    )
}
