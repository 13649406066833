import { CDSItem, RockpalastItem } from "../types"
import { EXCLUDED_TAGS } from "../configs/excludedTags"

export function getAmountOfTags(cdsItems: CDSItem[] | RockpalastItem[]): {
    [key: string]: number
} {
    const tags: string[] = []

    if (!cdsItems || cdsItems.length < 1) {
        return {}
    }

    cdsItems.map((item) => item.filterTags.map((tag) => tags.push(tag)))

    const returnTags: { [key: string]: number } = tags
        .filter(
            (tag) => tag.length > 1 && !EXCLUDED_TAGS.includes(tag.toLowerCase())
        )
        .reduce(
            // @ts-ignore
            // eslint-disable-next-line
            (ctn, tagName) => ((ctn[tagName] = ctn[tagName] + 1 || 1), ctn),
            {}
        )

    // https://stackoverflow.com/questions/1069666/sorting-object-property-by-values
    return Object.entries(returnTags)
        .sort(([, a], [, b]) => b - a)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
}

export function getTags(cdsItems: CDSItem[]): { id: string; name: string }[] {
    if (!cdsItems || cdsItems.length < 1) {
        return []
    }

    const tags = getAmountOfTags(cdsItems)

    const returnArray = Object.keys(tags)
        .sort((a, b) => a.localeCompare(b))
        .map((key) => ({
            id: key.toLowerCase(),
            name: key,
        }))

    returnArray.unshift({ id: "smart speaker", name: "Smart Speaker" })
    returnArray.unshift({ id: "wdr hörspiel", name: "WDR Hörspiel" })

    return returnArray
}
