import { useContext, useEffect, useState } from "react"
import ProductContext from "../../../contexts/ProductContext"
import { SinglePartDataGrid } from "./components/SinglePartDataGrid"
import { useNavigate, useSearchParams } from "react-router-dom"
import { CDSItem } from "../../../types"
import { ItemInformationDialog } from "../components/ItemInformationDialog"

export function SinglePartContainer() {
    const { productData, loadingProduct } = useContext(ProductContext)

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const id = searchParams.get("id")

    const [selectedItem, setSelectedItem] = useState<CDSItem | undefined>(undefined)

    useEffect(() => {
        if (productData?.singleparts) {
            const item = productData.singleparts.filter(
                (s: CDSItem) => s.id === id
            )?.[0]
            setSelectedItem(item)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productData.singleparts])

    const handleItemClick = (item: CDSItem) => {
        setSelectedItem(item)
        navigate(`?id=${item.id}`)
    }

    const handleDialogClose = () => {
        setSelectedItem(undefined)
        navigate(``)
    }

    if (loadingProduct) {
        return null
    }

    return (
        <>
            <SinglePartDataGrid
                singleParts={productData.singleparts}
                onItemClick={handleItemClick}
            />
            <ItemInformationDialog
                episode={selectedItem}
                open={!!selectedItem}
                onClose={handleDialogClose}
            />
        </>
    )
}
