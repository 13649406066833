import React, { useState } from "react"
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    TextField,
    Typography,
} from "@mui/material"
import { CheckCircle, XCircle } from "@phosphor-icons/react"
import { verifySsml } from "../../services/rest.service"
import { testSSML } from "./service"

export function SSMLTestPage() {
    const [audioFileUrl, setAudioFileUrl] = useState("")
    const [ssmlScript, setSsmlScript] = useState("")

    const [audioResult, setAudioResult] = useState(null)
    const [ssmlResult, setSsmlResult] = useState(null)

    const [audioLoading, setAudioLoading] = useState(false)
    const [ssmlLoading, setSsmlLoading] = useState(false)

    const handleAudioFileVerification = async () => {
        setAudioLoading(true)
        setAudioResult(null)

        const ssml = `<audio src="${audioFileUrl?.trim()}" />`
        const result = await testSSML(ssml)
        setAudioResult(result)
        setAudioLoading(false)
    }

    const handleSsmlTest = async () => {
        setSsmlLoading(true)
        setSsmlResult(null)

        const result = await testSSML(ssmlScript)
        setSsmlResult(result)
        setSsmlLoading(false)
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            m={1}
            p={1}
            style={{ flexGrow: 1 }}
        >
            <Grid container spacing={4} maxWidth={800}>
                <Grid item xs={12}>
                    <Card style={{ maxWidth: "none" }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        Audiofile Verification
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="audio-url"
                                        label="URL"
                                        placeholder="https://example.com/audiofile.mp3"
                                        variant="outlined"
                                        value={audioFileUrl}
                                        onChange={(e) =>
                                            setAudioFileUrl(e.target.value)
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {audioLoading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        <Button
                                            variant="contained"
                                            onClick={handleAudioFileVerification}
                                        >
                                            Verifizieren
                                        </Button>
                                    )}
                                </Grid>
                                {!!audioResult && (
                                    <ValidationResponse
                                        isValid={!audioResult.error}
                                        errorMessage={audioResult.content}
                                    />
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card style={{ maxWidth: "none" }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        SSML Test Util
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="ssml-script"
                                        label="SSML Script"
                                        multiline
                                        placeholder='Bitte gib hier das SSML-Script ein <break time="1s"/> das Du testen möchtest.'
                                        variant="outlined"
                                        value={ssmlScript}
                                        onChange={(e) =>
                                            setSsmlScript(e.target.value)
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            style: { fontFamily: "monospace" },
                                        }}
                                        rows={4}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {ssmlLoading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        <Button
                                            variant="contained"
                                            onClick={handleSsmlTest}
                                        >
                                            Testen
                                        </Button>
                                    )}
                                </Grid>
                                {!!ssmlResult && (
                                    <ValidationResponse
                                        isValid={!ssmlResult.error}
                                        errorMessage={ssmlResult.content}
                                    />
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

function ValidationResponse({ isValid, errorMessage }) {
    return (
        <Grid item xs={12} container alignItems="flex-start" wrap="nowrap">
            {isValid ? (
                <CheckCircle
                    weight="fill"
                    size={24}
                    color="green"
                    style={{ marginRight: "5px", flexShrink: 0 }}
                />
            ) : (
                <XCircle
                    weight="fill"
                    size={24}
                    color="red"
                    style={{ marginRight: "5px", flexShrink: 0 }}
                />
            )}

            <Typography variant="body1" color={isValid ? "green" : undefined}>
                {isValid
                    ? "Die Prüfung war erfolgreich: " + errorMessage
                    : "Die Prüfung war nicht erfolgreich: " + errorMessage}
            </Typography>
        </Grid>
    )
}
