import { IconButton, Tooltip, Snackbar } from "@mui/material"
import { useState } from "react"
import { Image, SealCheck } from "@phosphor-icons/react"
import { validURL } from "./lib/utils"
import { testSSML } from "../../pages/ssmlTest/service"

const VERIFICATION_STARTED = "Prüfung gestartet"
const VERIFICATION_FAILED = "Die Prüfung war nicht erfolgreich: "
const VERIFICATION_OK = "Die Prüfung war erfolgreich"

export const ssmlVerification = async (value, setToast) => {
    setToast({ open: true, message: VERIFICATION_STARTED })
    let result
    try {
        if (value && validURL(value)) {
            const ssml = `<audio src="${value?.trim()}" />`
            result = await testSSML(ssml)
        } else {
            result = await testSSML(value)
        }
    } catch (e) {
        result = {
            error: true,
            content: e.message,
        }
    }

    if (result.error) {
        setToast({
            open: true,
            message: VERIFICATION_FAILED + result.content,
        })
    } else {
        setToast({ open: true, message: VERIFICATION_OK })
    }
    return result
}

export const InputActions = ({ field, value }) => {
    const [toast, setToast] = useState({ open: false, message: "" })

    const handleClose = () => {
        setToast({ ...toast, open: false })
    }

    return (
        <>
            {field?.check === "ssml" && value ? (
                <Tooltip title="SSML verifizieren">
                    <IconButton
                    color="primary"
                    onClick={() => ssmlVerification(value, setToast)}>
                        <SealCheck />
                    </IconButton>
                </Tooltip>
            ) : field?.check === "image" && value ? (
                <Tooltip
                    title={
                        value && validURL(value) ? "Vorschau" : "Fehlerhaftes Format"
                    }
                >
                    <IconButton
                        color={value && validURL(value) ? "primary" : "error"}
                        href={value && validURL(value) ? value : undefined}
                        target="_blank"
                    >
                        <Image />
                    </IconButton>
                </Tooltip>
            ) : null}

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={toast.open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={toast.message}
            />
        </>
    )
}
